import {
  AppShell,
  NavLink,
  Text,
  ThemeIcon,
  MantineTheme,
} from '@mantine/core';
import {
  IconApps,
  IconFlare,
  IconListDetails,
  IconUser,
  IconWand,
  IconTrophy,
  IconRobot,
} from '@tabler/icons-react';
import React, { useMemo, useState } from 'react';
import { TFunction } from 'i18next';
import { AppRouteURL } from '../../AppRouteURL';
import { MenuNavLink } from './MenuNavLink';
import { useLocation } from 'react-router-dom';
import { isRouteInSection } from './NavUtils';

interface NavbarItemsProps {
  setOpen: (open: boolean) => void;
  t: TFunction;
}

export const NavbarItems: React.FC<NavbarItemsProps> = ({ setOpen, t }) => {
  const location = useLocation();

  // Define route prefixes for each section
  const profilePrefixes = [
    AppRouteURL.profileTabBasic,
    AppRouteURL.profileTabIntro,
    AppRouteURL.profileTabTimeline,
  ];

  const aiToolsPrefixes = [AppRouteURL.analyze, AppRouteURL.achievements];

  // Compute section active states based on current route
  const isProfileActive = useMemo(
    () => isRouteInSection(location.pathname, profilePrefixes),
    [location.pathname],
  );

  const isAIToolsActive = useMemo(
    () => isRouteInSection(location.pathname, aiToolsPrefixes),
    [location.pathname],
  );

  // User-controlled state overrides for sections
  // These are null by default, meaning we'll use the automatic behavior
  // When a user manually interacts, these will be set to true/false
  const [profileControlledState, setProfileControlledState] = useState<
    boolean | null
  >(null);
  const [aiToolsControlledState, setAiToolsControlledState] = useState<
    boolean | null
  >(null);

  // Calculate the actual open state - use controlled state if it's not null, otherwise use the active state
  const profileOpened =
    profileControlledState !== null ? profileControlledState : isProfileActive;
  const aiToolsOpened =
    aiToolsControlledState !== null ? aiToolsControlledState : isAIToolsActive;

  // When a section becomes active, reset the controlled state for all sections
  // This makes navigation between sections reset any manual overrides
  useMemo(() => {
    if (isProfileActive || isAIToolsActive) {
      setProfileControlledState(null);
      setAiToolsControlledState(null);
    }
  }, [isProfileActive, isAIToolsActive]);

  return (
    <AppShell.Section>
      <NavLink
        href="#profile-nav-section"
        label={t('header.profile')}
        leftSection={
          <ThemeIcon
            variant="light"
            size="lg"
            style={{
              background:
                'linear-gradient(135deg, var(--mantine-color-cyan-6), var(--mantine-color-teal-6))',
              border: 'none',
              boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05)',
            }}
          >
            <IconUser style={{ color: 'white', stroke: '1.5' }} />
          </ThemeIcon>
        }
        opened={profileOpened}
        onChange={setProfileControlledState}
        defaultOpened={isProfileActive}
        tabIndex={0}
        styles={(theme: MantineTheme) => ({
          root: {
            borderRadius: theme.radius.md,
            padding: '12px',
            transition: 'all 0.2s ease',
            '&[dataExpanded]': {
              backgroundColor: `var(--mantine-color-${theme.primaryColor}-0)`,
            },
          },
          label: {
            fontWeight: 500,
          },
        })}
      >
        <MenuNavLink
          leftSection={
            <ThemeIcon
              variant="light"
              size="md"
              style={{
                background: 'var(--mantine-color-teal-0)',
                border: 'none',
                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.02)',
              }}
            >
              <IconUser
                style={{
                  color: 'var(--mantine-color-teal-6)',
                  stroke: '1.5',
                }}
              />
            </ThemeIcon>
          }
          to={AppRouteURL.profileTabBasic}
          label={
            <Text size="sm" id="app-header-profile-link">
              {t('profile.personal')}
            </Text>
          }
          setOpen={setOpen}
        />
        <MenuNavLink
          leftSection={
            <ThemeIcon
              variant="light"
              size="md"
              style={{
                background: 'var(--mantine-color-teal-0)',
                border: 'none',
                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.02)',
              }}
            >
              <IconFlare
                style={{
                  color: 'var(--mantine-color-teal-6)',
                  stroke: '1.5',
                }}
              />
            </ThemeIcon>
          }
          to={AppRouteURL.profileTabIntro}
          label={<Text size="sm">{t('profile.intro')}</Text>}
          setOpen={setOpen}
        />
        <MenuNavLink
          leftSection={
            <ThemeIcon
              variant="light"
              size="md"
              style={{
                background: 'var(--mantine-color-teal-0)',
                border: 'none',
                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.02)',
              }}
            >
              <IconListDetails
                style={{
                  color: 'var(--mantine-color-teal-6)',
                  stroke: '1.5',
                }}
              />
            </ThemeIcon>
          }
          to={AppRouteURL.profileTabTimeline}
          label={<Text size="sm">{t('profile.timelineLabel')}</Text>}
          setOpen={setOpen}
        />
      </NavLink>

      <NavLink
        href="#ai-tools-section"
        label={t('navbar.aiHeader')}
        leftSection={
          <ThemeIcon
            variant="light"
            size="lg"
            style={{
              background:
                'linear-gradient(135deg, var(--mantine-color-violet-6), var(--mantine-color-grape-6))',
              border: 'none',
              boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05)',
            }}
          >
            <IconRobot style={{ color: 'white', stroke: '1.5' }} />
          </ThemeIcon>
        }
        opened={aiToolsOpened}
        onChange={setAiToolsControlledState}
        defaultOpened={isAIToolsActive}
        tabIndex={0}
        styles={(theme: MantineTheme) => ({
          root: {
            borderRadius: theme.radius.md,
            padding: '12px',
            transition: 'all 0.2s ease',
            '&[data-expanded]': {
              backgroundColor: `var(--mantine-color-${theme.primaryColor}-0)`,
            },
          },
          label: {
            fontWeight: 500,
          },
        })}
      >
        <MenuNavLink
          leftSection={
            <ThemeIcon
              variant="light"
              size="md"
              style={{
                background: 'var(--mantine-color-grape-0)',
                border: 'none',
                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.02)',
              }}
            >
              <IconWand
                style={{
                  color: 'var(--mantine-color-grape-6)',
                  stroke: '1.5',
                }}
              />
            </ThemeIcon>
          }
          to={AppRouteURL.analyze}
          label={<Text size="sm">{t('navbar.analyzer')}</Text>}
          setOpen={setOpen}
        />
        <MenuNavLink
          leftSection={
            <ThemeIcon
              variant="light"
              size="md"
              style={{
                background: 'var(--mantine-color-orange-0)',
                border: 'none',
                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.02)',
              }}
            >
              <IconTrophy
                style={{
                  color: 'var(--mantine-color-orange-6)',
                  stroke: '1.5',
                }}
              />
            </ThemeIcon>
          }
          to={AppRouteURL.achievements}
          label={<Text size="sm">{t('navbar.achievements')}</Text>}
          setOpen={setOpen}
        />
      </NavLink>

      <MenuNavLink
        leftSection={
          <ThemeIcon
            variant="light"
            size="lg"
            style={{
              background:
                'linear-gradient(135deg, var(--mantine-color-indigo-6), var(--mantine-color-blue-6))',
              border: 'none',
              boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05)',
            }}
          >
            <IconApps style={{ color: 'white', stroke: '1.5' }} />
          </ThemeIcon>
        }
        to={AppRouteURL.resumes.list}
        label={
          <Text size="sm" fw={500}>
            {t('applications.pageTitle')}
          </Text>
        }
        setOpen={setOpen}
      />
    </AppShell.Section>
  );
};
