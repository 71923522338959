import { NavLink, NavLinkProps, ThemeIcon, MantineTheme } from '@mantine/core';
import { motion } from 'framer-motion';
import React from 'react';
import { Link, LinkProps, useLocation, useNavigate } from 'react-router-dom';
import { navItemAnimation, activeItemAnimation } from './NavAnimations';

export type MenuNavLinkProps = NavLinkProps &
  LinkProps & { setOpen: (open: boolean) => void };

export const MenuNavLink: React.FC<MenuNavLinkProps> = ({
  setOpen,
  ...props
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const isActive = !props.disabled && location.pathname === props.to;

  return (
    <motion.div
      variants={navItemAnimation}
      initial="initial"
      animate="animate"
      exit="exit"
      whileHover={{ scale: 1.02 }}
      whileTap={{ scale: 0.98 }}
      style={{ position: 'relative' }}
    >
      {isActive && (
        <motion.div
          {...activeItemAnimation}
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            borderRadius: 'var(--mantine-radius-md)',
            background: 'var(--mantine-primary-color-light)',
            opacity: 0.12,
            zIndex: 0,
          }}
        />
      )}
      <NavLink
        component={Link}
        active={isActive}
        onClick={() => {
          setOpen(false);
          if (props.to) {
            void navigate(props.to);
          }
        }}
        aria-current={location.pathname === props.to ? 'page' : undefined}
        aria-expanded={props.opened}
        tabIndex={0}
        styles={(theme: MantineTheme) => ({
          root: {
            position: 'relative',
            borderRadius: theme.radius.md,
            padding: '12px',
            transition: 'all 0.2s ease',
            '&[dataActive]': {
              backgroundColor: 'transparent',
              color: `var(--mantine-color-${theme.primaryColor}-7)`,
            },
            '&:hover': {
              backgroundColor: 'transparent',
              transform: 'translateX(4px)',
            },
          },
          label: {
            fontWeight: 500,
          },
        })}
        {...props}
      />
    </motion.div>
  );
};
