import {
  Alert,
  Anchor,
  Button,
  Center,
  Container,
  Group,
  Loader,
  Paper,
  Stack,
  Table,
  Text,
  TextInput,
  Title,
  Transition,
  rem,
  Skeleton,
} from '@mantine/core';
import {
  IconCirclePlus,
  IconInfoCircle,
  IconSearch,
} from '@tabler/icons-react';
import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { AppRouteURL } from '../../AppRouteURL';
import { Bowl } from '../../components/Bowl/Bowl';
import { Pagination } from '@mantine/core';
import { isProfileEmpty } from '../../models/ProfileQueries';
import { RECORDS_PER_PAGE, useResumesQuery } from '../../queries/ResumeQueries';
import { ResumeTableRow } from './ResumeTableRow';

// const log = getLogger('ListResumesPage');

const EmptyProfileWarning: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Transition mounted={true} transition="fade" duration={400}>
      {(styles) => (
        <Paper withBorder p="xl" radius="md" shadow="sm" style={styles}>
          <Stack align="center" gap="md">
            <IconInfoCircle size={48} color="var(--mantine-color-blue-6)" />
            <Title order={3} ta="center">
              {t('applications.missingProfile')}
            </Title>
            <Text size="lg" ta="center" maw={500}>
              <Trans i18nKey="applications.missingProfileInfo">
                You need to
                <Anchor component={Link} to={AppRouteURL.profileTabBasic}>
                  update your profile
                </Anchor>
                before you can start creating applications!
              </Trans>
            </Text>
          </Stack>
        </Paper>
      )}
    </Transition>
  );
};

const NoResumesAlert: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Transition mounted={true} transition="fade" duration={400}>
      {(styles) => (
        <Paper withBorder p="xl" radius="md" shadow="sm" style={styles}>
          <Stack align="center" gap="md">
            <IconSearch size={48} color="var(--mantine-color-blue-6)" />
            <Title order={3} ta="center">
              {t('applications.noApplications')}
            </Title>
            <Text size="lg" ta="center" maw={500}>
              <Trans i18nKey="applications.noApplicationsInfo">
                You have not created any applications yet. Go ahead and
                <Anchor component={Link} to={AppRouteURL.resumes.create}>
                  create one
                </Anchor>
                !
              </Trans>
            </Text>
          </Stack>
        </Paper>
      )}
    </Transition>
  );
};

const NoSearchResults: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Stack align="center" gap="md" py="xl">
      <IconSearch size={48} color="var(--mantine-color-gray-5)" />
      <Text size="lg" c="dimmed" ta="center">
        {t('applications.noSearchResults')}
      </Text>
    </Stack>
  );
};

const TableSkeleton: React.FC = () => {
  // Generate 5 skeleton rows
  const rows = Array(5).fill(0);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.2 }}
    >
      <Paper withBorder radius="md" shadow="sm">
        <Table>
          <Table.Thead>
            <Table.Tr>
              <Table.Th style={{ width: '60%' }}>
                <Skeleton height={20} radius="sm" />
              </Table.Th>
              <Table.Th style={{ width: '25%' }}>
                <Skeleton height={20} radius="sm" />
              </Table.Th>
              <Table.Th style={{ width: '15%', textAlign: 'right' }}>
                <Skeleton height={20} radius="sm" />
              </Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {rows.map((_, index) => (
              <Table.Tr key={index}>
                <Table.Td>
                  <Skeleton height={24} radius="sm" />
                </Table.Td>
                <Table.Td>
                  <Skeleton height={24} radius="sm" />
                </Table.Td>
                <Table.Td>
                  <Group justify="flex-end" gap="xs">
                    <Skeleton height={32} circle />
                    <Skeleton height={32} circle />
                  </Group>
                </Table.Td>
              </Table.Tr>
            ))}
          </Table.Tbody>
        </Table>
      </Paper>
    </motion.div>
  );
};

export const ListResumesPage: React.FC = () => {
  const profileEmpty = isProfileEmpty();
  const { t } = useTranslation();
  const [page, setPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState('');

  const { data, isPending } = useResumesQuery({
    page,
    take: RECORDS_PER_PAGE,
    q: searchQuery,
  });

  const showEmptyState = !data?.meta?.itemCount;
  const isFiltering = !!searchQuery;

  const content = (
    <AnimatePresence mode="wait">
      {isPending ? (
        <TableSkeleton key="loading" />
      ) : profileEmpty ? (
        <EmptyProfileWarning key="empty-profile" />
      ) : (
        <motion.div
          key="table"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -20 }}
          transition={{ duration: 0.2 }}
        >
          <Paper withBorder radius="md" shadow="sm">
            <Stack p="md">
              <TextInput
                placeholder={t('applications.search')}
                leftSection={
                  <IconSearch style={{ width: rem(16), height: rem(16) }} />
                }
                value={searchQuery}
                onChange={(event) => {
                  setSearchQuery(event.currentTarget.value);
                  setPage(1); // Reset to first page when searching
                }}
              />
              {showEmptyState ? (
                isFiltering ? (
                  <NoSearchResults />
                ) : (
                  <NoResumesAlert />
                )
              ) : (
                <>
                  <Table highlightOnHover striped>
                    <Table.Thead>
                      <Table.Tr>
                        <Table.Th style={{ width: '60%' }}>
                          {t('applications.title')}
                        </Table.Th>
                        <Table.Th style={{ width: '25%' }}>
                          {t('applications.created')}
                        </Table.Th>
                        <Table.Th style={{ width: '15%', textAlign: 'right' }}>
                          {t('applications.actions')}
                        </Table.Th>
                      </Table.Tr>
                    </Table.Thead>
                    <Table.Tbody>
                      {data.resumes.map((resume) => (
                        <ResumeTableRow key={resume.id} resume={resume} />
                      ))}
                    </Table.Tbody>
                  </Table>
                  {data.meta.pageCount > 1 && (
                    <Group justify="center">
                      <Pagination
                        value={page}
                        onChange={(newPage) => {
                          setPage(newPage);
                        }}
                        total={data.meta.pageCount}
                        withEdges
                        getControlProps={(control) => ({
                          'aria-label': t(`applications.pagination.${control}`),
                        })}
                      />
                    </Group>
                  )}
                </>
              )}
            </Stack>
          </Paper>
        </motion.div>
      )}
    </AnimatePresence>
  );

  return (
    <Container size="lg">
      <Bowl title={t('applications.pageTitle')} />

      <Stack gap="xl">
        <Group justify="space-between" align="center">
          <Stack gap={4}>
            <Title order={2}>{t('applications.pageTitle')}</Title>
            {!isPending && data?.meta && (
              <Text c="dimmed" size="sm">
                {t('applications.totalCount', { count: data.meta.itemCount })}
              </Text>
            )}
          </Stack>
          <Transition
            mounted={!profileEmpty}
            transition="slide-left"
            duration={400}
          >
            {(styles) => (
              <Button
                component={Link}
                to={AppRouteURL.resumes.create}
                variant="gradient"
                gradient={{ from: 'blue', to: 'violet' }}
                leftSection={
                  <IconCirclePlus style={{ width: rem(20), height: rem(20) }} />
                }
                size="md"
                style={styles}
              >
                {t('applications.newApplication')}
              </Button>
            )}
          </Transition>
        </Group>

        {content}
      </Stack>
    </Container>
  );
};
