/**
 * Route utility functions for the navigation components
 */

/**
 * Checks if a given path starts with any of the provided prefixes
 * @param path Current path to check
 * @param prefixes Array of route prefixes to match against
 * @returns boolean indicating if the path matches any prefix
 */
export const isRouteInSection = (path: string, prefixes: string[]): boolean => {
  return prefixes.some((prefix) => path.startsWith(prefix));
};
