import { Avatar, AvatarProps } from '@mantine/core';
import { IconBuildingSkyscraper } from '@tabler/icons-react';
import React, { useEffect, useState } from 'react';
import Api from '../../Api';
import { ApiRoutes } from '../../ApiRoutes';

interface CompanyLogoProps extends Omit<AvatarProps, 'src'> {
  companyName: string;
}

export const CompanyLogo: React.FC<CompanyLogoProps> = ({
  companyName,
  ...props
}) => {
  const [hasError, setHasError] = useState(false);
  const [isLogoAvailable, setIsLogoAvailable] = useState<boolean | null>(null);

  useEffect(() => {
    if (!companyName) {
      setIsLogoAvailable(false);
      return;
    }

    const checkLogoAvailability = async () => {
      setIsLogoAvailable(null);
      try {
        const response = await Api.ky.head(
          `${ApiRoutes.CompanyLogos}/${encodeURIComponent(companyName)}`,
        );
        setIsLogoAvailable(response.ok);
      } catch {
        setIsLogoAvailable(false);
      }
    };

    void checkLogoAvailability();
  }, [companyName]);

  if (!companyName || hasError || !isLogoAvailable) {
    return (
      <Avatar {...props}>
        <IconBuildingSkyscraper style={{ width: '60%', height: '60%' }} />
      </Avatar>
    );
  }

  return (
    <Avatar
      src={`/api/company-logos/${encodeURIComponent(companyName)}`}
      onError={() => {
        setHasError(true);
      }}
      styles={{
        image: {
          objectFit: 'contain',
          width: '100%',
          height: '100%',
        },
      }}
      {...props}
    >
      <IconBuildingSkyscraper style={{ width: '60%', height: '60%' }} />
    </Avatar>
  );
};
