import { AppShell, Center } from '@mantine/core';
import React from 'react';
import { motion } from 'framer-motion';
import { CreateResumeButton } from '../CreateResumeButton/CreateResumeButton';

interface NavbarHeaderProps {
  setOpen: (open: boolean) => void;
}

export const NavbarHeader: React.FC<NavbarHeaderProps> = ({ setOpen }) => {
  return (
    <AppShell.Section>
      <motion.div
        initial={{ opacity: 0, scale: 0.9 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.3 }}
      >
        <Center mb="xl">
          <CreateResumeButton
            onClick={() => {
              setOpen(false);
            }}
          />
        </Center>
      </motion.div>
    </AppShell.Section>
  );
};
