import { AppShell, Divider, Text } from '@mantine/core';
import React from 'react';
import { motion } from 'framer-motion';
import { TFunction } from 'i18next';

interface NavbarFooterProps {
  t: TFunction;
}

export const NavbarFooter: React.FC<NavbarFooterProps> = ({ t }) => {
  return (
    <AppShell.Section>
      <Divider mb="sm" />
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.5 }}
      >
        <Text fz="xs" c="dimmed" fw={500}>
          {t('login.copyrightFooter', { year: new Date().getFullYear() })}
        </Text>
      </motion.div>
    </AppShell.Section>
  );
};
