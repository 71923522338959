import {
  Paper,
  RingProgress,
  Stack,
  Text,
  Title,
  rem,
  Group,
  ThemeIcon,
} from '@mantine/core';
import { IconBriefcase, IconTrophy } from '@tabler/icons-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { AppRouteURL } from '../../AppRouteURL';
import { useResumeCountQuery } from '../../queries/ResumeQueries';
import { motion } from 'framer-motion';

import classes from './HomeCard.module.css';

const ApplicationStatusCard: React.FC = () => {
  const { t } = useTranslation();
  const { data } = useResumeCountQuery();
  const navigate = useNavigate();

  const totalApplications = data?.count || 0;
  const milestone = Math.ceil(totalApplications / 10) * 10;
  const progress = (totalApplications / milestone) * 100;

  return (
    <motion.div
      layout
      style={{ height: '100%' }}
      whileHover={{ scale: 1.02 }}
      whileTap={{ scale: 0.98 }}
    >
      <Paper
        shadow="lg"
        radius="xl"
        p="xl"
        onClick={() => {
          void navigate(AppRouteURL.resumes.list);
        }}
        className={classes.homeCard}
      >
        <div className={classes.homeCardWrapper}>
          <Group justify="space-between" align="center">
            <Title order={5}>{t('header.applications')}</Title>
            <IconBriefcase
              style={{ width: rem(24), height: rem(24) }}
              color="var(--mantine-color-blue-filled)"
            />
          </Group>
          <div className={classes.homeCardContent}>
            <Stack gap="md" align="center" justify="center" h="100%" w="100%">
              <RingProgress
                size={160}
                thickness={12}
                roundCaps
                sections={[{ value: progress, color: 'blue' }]}
                label={
                  <Stack gap={4} align="center">
                    <Group gap={4} align="baseline" justify="center">
                      <Text fw={700} size="2.5rem">
                        {totalApplications}
                      </Text>
                      <Text size="sm" c="dimmed">
                        / {milestone}
                      </Text>
                    </Group>
                    {totalApplications > 0 && totalApplications % 10 === 0 && (
                      <ThemeIcon
                        size="lg"
                        radius="xl"
                        color="yellow"
                        variant="light"
                      >
                        <IconTrophy
                          style={{ width: rem(18), height: rem(18) }}
                        />
                      </ThemeIcon>
                    )}
                  </Stack>
                }
              />
              <Stack gap={4} align="center" w="100%">
                <Text size="sm" c="dimmed" ta="center" w="100%">
                  {totalApplications > 0
                    ? t('home.applications.viewAll')
                    : t('home.applications.createFirst')}
                </Text>
                {totalApplications > 0 && (
                  <Text size="xs" c="dimmed" ta="center" w="100%">
                    {t('home.applications.nextMilestone', { milestone })}
                  </Text>
                )}
              </Stack>
            </Stack>
          </div>
        </div>
      </Paper>
    </motion.div>
  );
};

export default ApplicationStatusCard;
