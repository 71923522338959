import {
  Box,
  Button,
  FocusTrap,
  Group,
  Modal,
  ModalProps,
  Stack,
  Text,
  useMantineTheme,
  useComputedColorScheme,
} from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { openConfirmModal } from '@mantine/modals';
import { IconCheck } from '@tabler/icons-react';
import { Form, Formik, FormikConfig, FormikHelpers, FormikProps } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { IUserProfile } from '../../models/Profile';
import { useProfileMutation } from '../../models/ProfileQueries';

interface ProfileModalProps<T extends Partial<IUserProfile>> {
  opened: boolean;
  onClose: () => void;
  title: string;
  initialValues: FormikConfig<T>['initialValues'];
  validationSchema: FormikConfig<T>['validationSchema'];
  children: (formikProps: FormikProps<T>) => React.ReactNode;
  modalProps?: Partial<ModalProps>;
}

export const ProfileModal = <T extends Partial<IUserProfile>>({
  opened,
  onClose,
  title,
  initialValues,
  validationSchema,
  children,
  modalProps,
}: ProfileModalProps<T>) => {
  const { t } = useTranslation();
  const theme = useMantineTheme();
  const computedColorScheme = useComputedColorScheme('light', {
    getInitialValueInEffect: true,
  });
  const isDark = computedColorScheme === 'dark';
  const isSmallScreen = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`);

  const updateProfileMutation = useProfileMutation();

  const onSubmit = (values: T, actions: FormikHelpers<T>) =>
    updateProfileMutation
      .mutateAsync(values)
      .then(() => {
        actions.resetForm({ values });
        onClose();
      })
      .catch((error) => {
        actions.setStatus(error);
      })
      .finally(() => {
        actions.setSubmitting(false);
      });

  const confirmClose = (dirty: boolean) => {
    if (dirty)
      openConfirmModal({
        title: t('profile.unsavedChangesTitle'),
        centered: true,
        children: <Text size="sm">{t('profile.unsavedChangesText')}</Text>,
        labels: {
          confirm: t('labels.close'),
          cancel: t('labels.cancel'),
        },
        confirmProps: { color: 'red' },
        onConfirm: onClose,
      });
    else onClose();
  };

  return (
    <Modal
      opened={opened}
      onClose={onClose}
      title={
        <Text fz="lg" fw="bolder">
          {title}
        </Text>
      }
      size="xl"
      withCloseButton={false}
      fullScreen={isSmallScreen}
      styles={{
        body: {
          maxHeight: isSmallScreen
            ? 'calc(100vh - 140px)'
            : 'calc(90vh - 5rem)',
          overflowY: 'auto',
          paddingBottom: isSmallScreen ? '80px' : undefined,
        },
        content: {
          maxHeight: isSmallScreen ? '100vh' : '90vh',
          display: 'flex',
          flexDirection: 'column',
        },
        header: {
          padding: isSmallScreen ? '1rem 1rem 0.5rem' : '1.5rem 2rem 0.5rem',
          position: 'sticky',
          top: 0,
          backgroundColor: isDark ? theme.colors.dark[7] : theme.white,
          zIndex: 10,
          borderBottom: `1px solid ${isDark ? theme.colors.dark[4] : theme.colors.gray[3]}`,
        },
        inner: {
          padding: 0,
        },
      }}
      {...modalProps}
    >
      <Box p={isSmallScreen ? '1rem' : '2rem'} style={{ flex: 1 }}>
        <FocusTrap.InitialFocus />
        {/* eslint-disable @typescript-eslint/no-unsafe-assignment */}
        <Formik<T>
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {(formikProps) => (
            <Form
              style={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Stack style={{ flex: 1 }}>{children(formikProps)}</Stack>
              <Box
                style={{
                  position: isSmallScreen ? 'fixed' : 'static',
                  bottom: 0,
                  left: 0,
                  right: 0,
                  padding: isSmallScreen ? '1rem' : 0,
                  backgroundColor: isDark ? theme.colors.dark[7] : theme.white,
                  borderTop: isSmallScreen
                    ? `1px solid ${isDark ? theme.colors.dark[4] : theme.colors.gray[3]}`
                    : 'none',
                  zIndex: 10,
                }}
              >
                <Group
                  justify="flex-end"
                  mt={isSmallScreen ? 0 : 'xl'}
                  gap="sm"
                >
                  <Button
                    variant="light"
                    size="sm"
                    onClick={() => {
                      confirmClose(formikProps.dirty);
                    }}
                    disabled={formikProps.isSubmitting}
                  >
                    {t('labels.close')}
                  </Button>
                  <Button
                    type="submit"
                    size="sm"
                    variant="filled"
                    leftSection={<IconCheck />}
                    loading={formikProps.isSubmitting}
                    disabled={
                      !formikProps.isValid ||
                      formikProps.isSubmitting ||
                      !formikProps.dirty
                    }
                  >
                    {formikProps.isSubmitting
                      ? t('labels.saving')
                      : t('labels.save')}
                  </Button>
                </Group>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
};
