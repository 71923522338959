import {
  ActionIcon,
  Button,
  Center,
  Group,
  Paper,
  Stack,
  Text,
  Timeline,
  Title,
  useMantineTheme,
} from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import {
  IconBuildingSkyscraper,
  IconNewSection,
  IconPencil,
  IconPlaylistAdd,
} from '@tabler/icons-react';
import { motion, AnimatePresence } from 'framer-motion';
import dayjs from 'dayjs';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import WhatIsThis from '../../components/WhatIsThis/WhatIsThis';
import { PRODUCT_NAME } from '../../Constants';
import { IUserProfileSection } from '../../models/Profile';
import { useProfileQuery } from '../../models/ProfileQueries';
import { MultilineText } from './MultilineText';
import { TimelineModal } from './TimelineModal';
import { CompanyLogo } from './CompanyLogo';

import classes from './TimelineTab.module.css';

const timelineItemAnimation = {
  initial: { opacity: 0, y: 20 },
  animate: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: -20 },
};

export const TimelineTab: React.FC<{ disabled?: boolean }> = ({
  disabled = false,
}) => {
  const { t } = useTranslation();
  const { data: profile, isPending } = useProfileQuery();
  const [modalOpened, setModalOpened] = useState(false);
  const [currentSection, setCurrentSection] =
    useState<IUserProfileSection | null>(null);
  const [lastEditedSection, setLastEditedSection] =
    useState<IUserProfileSection | null>(null);

  const theme = useMantineTheme();
  const isSmallScreen = useMediaQuery(`(max-width: ${theme.breakpoints.xs})`);

  const dateFormat = isSmallScreen ? 'MM/YYYY' : 'MMMM YYYY';

  const sections = useMemo(() => {
    if (profile?.sections) {
      return profile.sections.sort((a, b) => {
        const fromDiff = dayjs(b.from || 0).diff(dayjs(a.from || 0));
        const toDiff = dayjs(b.to || '31-12-2099').diff(
          dayjs(a.to || '31-12-2099'),
        );
        return fromDiff || toDiff;
      });
    }
    return [];
  }, [profile?.sections]);

  const handleEdit = (section: IUserProfileSection) => {
    setLastEditedSection(null);
    setCurrentSection(section);
    setModalOpened(true);
  };

  const handleClose = () => {
    setLastEditedSection(currentSection);
    setCurrentSection(null);
    setModalOpened(false);
  };

  const handleAdd = () => {
    setCurrentSection({
      title: '',
      company: '',
      from: '',
      to: '',
      description: '',
    } as IUserProfileSection);
    setModalOpened(true);
  };

  if (isPending) {
    return <Text>{t('labels.loading')}</Text>;
  }

  return (
    <Stack my="lg" gap="xl" pb="lg">
      <WhatIsThis
        message={t('profile.timelineHelp', { brand: PRODUCT_NAME })}
      />

      {(!sections || sections.length === 0) && (
        <Center mt="xl">
          <Text size="lg" c="dimmed">
            {t('profile.noTimeline')}
          </Text>
        </Center>
      )}

      <Timeline
        active={sections.length}
        lineWidth={3}
        bulletSize={28}
        color="blue"
      >
        <AnimatePresence>
          {sections.map((section, index) => (
            <Timeline.Item
              key={section.id ?? index}
              lineVariant={index + 1 === sections.length ? 'dashed' : 'solid'}
              bullet={
                <Center
                  style={{
                    width: '100%',
                    height: '100%',
                    background: theme.colors.blue[6],
                    borderRadius: '50%',
                  }}
                >
                  <IconBuildingSkyscraper
                    size={14}
                    color="white"
                    stroke={2.5}
                  />
                </Center>
              }
              className={
                section.id === lastEditedSection?.id ? classes.flashSection : ''
              }
            >
              <motion.div
                initial="initial"
                animate="animate"
                exit="exit"
                variants={timelineItemAnimation}
                transition={{ duration: 0.3, ease: [0.4, 0, 0.2, 1] }}
              >
                <Paper
                  className={classes.timelineSection}
                  onClick={() => {
                    handleEdit(section);
                  }}
                  shadow="xs"
                >
                  <Group
                    justify="space-between"
                    align="flex-start"
                    wrap="nowrap"
                  >
                    <Group align="flex-start" wrap="nowrap" gap="md">
                      <CompanyLogo
                        companyName={section.company}
                        size="lg"
                        radius="md"
                      />
                      <Stack gap="xs">
                        <div>
                          <Title order={3} fw={500}>
                            {section.title ?? t('timeline.titlePlaceholder')}
                          </Title>
                          <Title order={4} fw={400}>
                            {section.company ??
                              t('timeline.companyPlaceholder')}
                          </Title>
                        </div>
                        <Text c="dimmed">
                          {section.from
                            ? dayjs(section.from).format(dateFormat)
                            : t('timeline.startDatePlaceholder')}{' '}
                          -{' '}
                          {section.to
                            ? dayjs(section.to).format(dateFormat)
                            : t('timeline.present')}
                        </Text>
                      </Stack>
                    </Group>

                    <ActionIcon
                      variant="subtle"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleEdit(section);
                      }}
                      size="md"
                      disabled={disabled}
                      color="blue"
                    >
                      <IconPencil size={18} />
                    </ActionIcon>
                  </Group>

                  <MultilineText
                    text={
                      section.description ??
                      t('timeline.descriptionPlaceholder')
                    }
                  />
                </Paper>
              </motion.div>
            </Timeline.Item>
          ))}
        </AnimatePresence>

        <Timeline.Item
          bullet={
            <Center
              style={{
                width: '100%',
                height: '100%',
                background: theme.colors.gray[1],
                borderRadius: '50%',
              }}
            >
              <IconNewSection size={14} color={theme.colors.gray[6]} />
            </Center>
          }
        >
          <motion.div whileHover={{ scale: 1.02 }} whileTap={{ scale: 0.98 }}>
            <Button
              variant="light"
              size="sm"
              leftSection={<IconPlaylistAdd size={16} />}
              onClick={handleAdd}
              disabled={disabled}
              className={classes.addButton}
            >
              {t('timeline.addSectionLabel')}
            </Button>
          </motion.div>
        </Timeline.Item>
      </Timeline>

      <TimelineModal
        opened={modalOpened}
        onClose={handleClose}
        section={currentSection}
        sections={sections}
        profile={profile}
      />
    </Stack>
  );
};
