import {
  Button,
  Card,
  Group,
  Image,
  Loader,
  Stack,
  Text,
  Title,
  useMantineTheme,
} from '@mantine/core';
import { IconCheck, IconX } from '@tabler/icons-react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ApiRoutes } from '../../../../ApiRoutes';

interface WebsitePreviewProps {
  resumeId: string;
  onConfirm: () => void;
  onReject: () => void;
}

export const WebsitePreview: React.FC<WebsitePreviewProps> = ({
  resumeId,
  onConfirm,
  onReject,
}) => {
  const { t } = useTranslation();
  const theme = useMantineTheme();
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  return (
    <div>
      <Stack gap="xl" align="center" py="xl">
        <div>
          <Stack gap="xs" align="center" maw={600}>
            <Title
              order={2}
              ta="center"
              style={{ color: theme.colors.blue[7] }}
            >
              {t('create.wizard.preview.title')}
            </Title>
            <Text size="lg" c="dimmed" ta="center">
              {t('create.wizard.preview.description')}
            </Text>
          </Stack>
        </div>

        <div
          style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
        >
          <Card
            shadow="sm"
            padding="lg"
            radius="md"
            withBorder
            style={{
              width: '100%',
              maxWidth: 800,
              transform: isImageLoaded ? 'translateY(0)' : 'translateY(20px)',
              opacity: isImageLoaded ? 1 : 0,
              transition: 'transform 600ms ease, opacity 600ms ease',
            }}
          >
            <Stack gap="md">
              <div
                style={{
                  maxHeight: '60vh',
                  overflow: 'auto',
                  backgroundColor: theme.colors.gray[0],
                  borderRadius: theme.radius.md,
                  position: 'relative',
                }}
              >
                {!isImageLoaded && (
                  <Stack
                    gap="md"
                    align="center"
                    py="xl"
                    style={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      backgroundColor: theme.colors.gray[0],
                      zIndex: 1,
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <Loader size="lg" />
                    <Text size="sm" c="dimmed">
                      {t('create.wizard.preview.loadingScreenshot')}
                    </Text>
                  </Stack>
                )}
                <Image
                  src={`/api/${ApiRoutes.Resumes}/${resumeId}/screenshot`}
                  alt={t('create.wizard.preview.screenshotAlt')}
                  style={{ width: '100%', height: 'auto' }}
                  onLoad={() => {
                    setIsImageLoaded(true);
                  }}
                />
              </div>
            </Stack>
          </Card>
        </div>

        <div style={{ opacity: isImageLoaded ? 1 : 0 }}>
          <Group gap="xl">
            <Button
              size="lg"
              leftSection={<IconX size={20} />}
              onClick={onReject}
              variant="light"
              color="red"
            >
              {t('create.wizard.preview.wrong')}
            </Button>
            <Button
              size="lg"
              leftSection={<IconCheck size={20} />}
              onClick={onConfirm}
              variant="gradient"
              gradient={{
                from: theme.colors.blue[5],
                to: theme.colors.violet[5],
              }}
            >
              {t('create.wizard.preview.correct')}
            </Button>
          </Group>
        </div>
      </Stack>
    </div>
  );
};
