import dayjs from 'dayjs';
import { Card, Text, Tooltip, useMantineTheme } from '@mantine/core';
import HeatMap from '@uiw/react-heat-map';
import { useTranslation } from 'react-i18next';
import { useResumeActivityQuery } from '../../queries/ResumeQueries';

const ApplicationActivityCard = () => {
  const { t } = useTranslation();
  const theme = useMantineTheme();
  const { data: activityData } = useResumeActivityQuery();

  const value =
    activityData?.map((item) => ({
      date: item.date,
      count: item.count,
    })) ?? [];

  return (
    <Card withBorder radius="md" p="xl" h="100%">
      <Text size="lg" fw={500} mb="md">
        {t('home.applicationActivity.title')}
      </Text>
      <HeatMap
        value={value}
        width="100%"
        style={{ color: theme.colors[theme.primaryColor][6] }}
        startDate={new Date(Date.now() - 365 * 24 * 60 * 60 * 1000)}
        rectSize={12}
        space={4}
        legendCellSize={0}
        rectRender={(props, data) => {
          const date = dayjs(data.date).format('LL');
          const label = t('home.applicationActivity.tooltip', {
            count: data.count || 0,
            date,
          });
          return (
            <Tooltip label={label}>
              <rect {...props} />
            </Tooltip>
          );
        }}
      />
    </Card>
  );
};

export default ApplicationActivityCard;
