import { AppShell, Box, ScrollArea } from '@mantine/core';
import { useComputedColorScheme } from '@mantine/core';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { AnimatePresence, LayoutGroup, motion } from 'framer-motion';
import { useResumesQuery } from '../../queries/ResumeQueries';
import { NavbarHeader } from './NavbarHeader';
import { NavbarItems } from './NavbarItems';
import { ApplicationMenu } from './ApplicationMenu';
import { NavbarFooter } from './NavbarFooter';
import { navGroupAnimation } from './NavAnimations';

export const AppNavbar: React.FC<{ setOpen: (open: boolean) => void }> = ({
  setOpen,
}) => {
  const { t } = useTranslation();
  const { resumeId } = useParams();
  const computedColorScheme = useComputedColorScheme('light');

  const { data: resumes } = useResumesQuery();

  const currentResume = useMemo(
    () => resumes?.resumes.find((r) => r.id === resumeId),
    [resumes, resumeId],
  );

  return (
    <AppShell.Navbar
      p="md"
      style={{
        background:
          computedColorScheme === 'dark'
            ? `var(--mantine-color-dark-8)`
            : `var(--mantine-color-gray-0)`,
        borderRight: `1px solid ${
          computedColorScheme === 'dark'
            ? `var(--mantine-color-dark-4)`
            : `var(--mantine-color-gray-2)`
        }`,
      }}
    >
      <ScrollArea
        scrollbars="y"
        styles={{
          viewport: { display: 'block', flexGrow: 1 },
          root: { display: 'block', flexGrow: 1 },
        }}
      >
        <Box
          style={{ display: 'block' }}
          maw={{ base: '100%', sm: 180, md: 280 }}
        >
          <NavbarHeader setOpen={setOpen} />

          <AnimatePresence mode="wait">
            <motion.div
              key="nav-sections"
              variants={navGroupAnimation}
              initial="initial"
              animate="animate"
              exit="exit"
            >
              <LayoutGroup>
                <NavbarItems setOpen={setOpen} t={t} />

                {currentResume && (
                  <ApplicationMenu
                    setOpen={setOpen}
                    resume={currentResume}
                    t={t}
                  />
                )}
              </LayoutGroup>
            </motion.div>
          </AnimatePresence>
        </Box>
      </ScrollArea>
      <NavbarFooter t={t} />
    </AppShell.Navbar>
  );
};
