import {
  Box,
  Button,
  Flex,
  Group,
  Modal,
  Stack,
  Text,
  TextInput,
  Tooltip,
  useMantineTheme,
  Paper,
  rgba,
  useComputedColorScheme,
  Title,
} from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { openConfirmModal } from '@mantine/modals';
import {
  IconBuildingSkyscraper,
  IconCalendar,
  IconCheck,
  IconTrash,
  IconSparkles,
  IconStars,
} from '@tabler/icons-react';
import { Field, Form, Formik, FormikHelpers, FormikProps } from 'formik';
import React, { useMemo, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { IUserProfile, IUserProfileSection } from '../../models/Profile';
import { useProfileMutation } from '../../models/ProfileQueries';
import { useTimelineSuggestionMutation } from '../../queries/SuggestionQueries';
import CodeMirror, {
  EditorView,
  ReactCodeMirrorRef,
} from '@uiw/react-codemirror';
import { markdown } from '@codemirror/lang-markdown';
import { MultilineText } from './MultilineText';
import { useUserQuery, SubscriptionType } from '../../models/User';
import { useNavigate } from 'react-router-dom';
import { AppRouteURL } from '../../AppRouteURL';
import classes from './TimelineModal.module.css';

import { Logger } from 'tslog';

const logger = new Logger({ name: 'TimelineModal' });

type TimelineProfileValues = IUserProfileSection;

const TimelineSchema = Yup.object().shape({
  title: Yup.string().required('Title is required'),
  company: Yup.string().required('Company is required'),
  from: Yup.string().required('Start date is required'),
  to: Yup.string().optional(),
  description: Yup.string().optional(),
});

const FormLabel: React.FC<{ label: string; tooltip: string }> = ({
  label,
  tooltip,
}) => (
  <Tooltip
    label={tooltip}
    multiline
    w="15rem"
    events={{ hover: true, focus: true, touch: true }}
    withArrow
    openDelay={500}
  >
    <Text td="dotted underline" size="sm">
      {label}
    </Text>
  </Tooltip>
);

export const TimelineModal: React.FC<{
  opened: boolean;
  onClose: () => void;
  section: IUserProfileSection | null;
  sections: IUserProfileSection[] | null;
  profile?: IUserProfile | null;
}> = ({ opened, onClose, section, sections = [], profile }) => {
  const { t } = useTranslation();
  const { data: user } = useUserQuery();
  const updateProfileMutation = useProfileMutation();
  const generateSuggestionMutation = useTimelineSuggestionMutation();
  const navigate = useNavigate();

  const theme = useMantineTheme();
  const computedColorScheme = useComputedColorScheme('light', {
    getInitialValueInEffect: true,
  });
  const isDark = computedColorScheme === 'dark';
  const isSmallScreen = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`);

  const editorRef = useRef<ReactCodeMirrorRef>(null);

  const triggerOptimization = useCallback(async () => {
    if (!editorRef.current?.view) return;

    if (user?.subscriptionType !== SubscriptionType.PRO) {
      openConfirmModal({
        title: <Title order={3}>{t('subscription.unlockAIFeatures')}</Title>,
        centered: true,
        size: 'lg',
        styles: {
          content: {
            padding: '1.5rem',
          },
          body: {
            padding: '1.5rem 0 0',
          },
        },
        children: (
          <Stack gap="xl">
            <Text size="sm">{t('subscription.upgradeForFeature')}</Text>
            <Stack gap="md">
              <Group gap="xs">
                <IconSparkles
                  size="1.25rem"
                  style={{ color: theme.colors.blue[5] }}
                />
                <Text size="sm" fw={500}>
                  {t('subscription.optimizeFeature1')}
                </Text>
              </Group>
              <Group gap="xs">
                <IconCheck
                  size="1.25rem"
                  style={{ color: theme.colors.blue[5] }}
                />
                <Text size="sm" fw={500}>
                  {t('subscription.optimizeFeature2')}
                </Text>
              </Group>
              <Group gap="xs">
                <IconStars
                  size="1.25rem"
                  style={{ color: theme.colors.blue[5] }}
                />
                <Text size="sm" fw={500}>
                  {t('subscription.optimizeFeature3')}
                </Text>
              </Group>
            </Stack>
          </Stack>
        ),
        labels: {
          confirm: t('subscription.upgradeToPro'),
          cancel: t('labels.cancel'),
        },
        confirmProps: {
          size: 'lg',
          variant: 'gradient',
          gradient: { from: 'blue', to: 'cyan' },
        },
        onConfirm: () => {
          void navigate(AppRouteURL.subscribe);
        },
      });
      return;
    }

    const text = editorRef.current.view.state.doc.toString();

    try {
      const { suggestion } = await generateSuggestionMutation.mutateAsync({
        currentText: text,
        sectionId: section?.id,
        cursorPosition: text.length,
      });

      logger.info(`Generated optimized text: ${suggestion}`);

      openConfirmModal({
        title: <Title order={3}>{t('timeline.optimizeTitle')}</Title>,
        centered: true,
        size: 'lg',
        styles: {
          content: {
            padding: '1.5rem',
          },
          body: {
            padding: '1.5rem 0 0',
          },
        },
        children: (
          <Stack gap="1.5rem">
            <Text size="sm">{t('timeline.optimizeConfirm')}</Text>
            <Paper p="1.5rem" withBorder bg={isDark ? 'dark.6' : 'gray.0'}>
              <MultilineText text={suggestion} />
            </Paper>
          </Stack>
        ),
        labels: {
          confirm: t('timeline.optimizeAccept'),
          cancel: t('timeline.optimizeCancel'),
        },
        onConfirm: () => {
          if (editorRef.current?.view) {
            const view = editorRef.current.view;
            const transaction = view.state.update({
              changes: {
                from: 0,
                to: view.state.doc.length,
                insert: suggestion,
              },
            });
            view.dispatch(transaction);
          }
        },
      });
    } catch (error) {
      logger.error(
        `Failed to generate optimized text: ${error instanceof Error ? error.message : 'Unknown error'}`,
      );
    }
  }, [
    generateSuggestionMutation,
    section?.id,
    t,
    user?.subscriptionType,
    navigate,
    theme.colors,
  ]);

  const initialValues = {
    id: section?.id,
    title: section?.title ?? '',
    company: section?.company ?? '',
    from: section?.from ?? '',
    to: section?.to ?? '',
    description: section?.description ?? '',
  } as TimelineProfileValues;

  const handleSubmit = async (
    values: TimelineProfileValues,
    actions: FormikHelpers<TimelineProfileValues>,
  ) => {
    try {
      const updatedSections = values.id
        ? sections?.map((s) => (s.id === values.id ? values : s))
        : [...(sections || []), values];

      await updateProfileMutation.mutateAsync({
        ...profile,
        sections: updatedSections,
      });
      actions.resetForm({ values });
      onClose();
    } catch (error) {
      actions.setStatus(error);
    } finally {
      actions.setSubmitting(false);
    }
  };

  const handleDelete = () => {
    openConfirmModal({
      title: t('profile.deleteTimelineEntryTitle'),
      centered: true,
      children: (
        <Text size="sm">
          {section?.title
            ? t('profile.deleteTimelineEntryText', {
                entry: section.title,
              })
            : t('profile.deleteTimelineEntryTextGeneric')}
        </Text>
      ),
      labels: {
        confirm: t('labels.delete'),
        cancel: t('labels.cancel'),
      },
      confirmProps: { color: 'red' },
      onConfirm: () => {
        if (section) {
          const updatedSections =
            sections?.filter((s) => s.id !== section.id) || [];
          updateProfileMutation.mutate({
            ...profile,
            sections: updatedSections,
          });
          onClose();
        }
      },
    });
  };

  const handleClose = useCallback(
    (formikProps: FormikProps<TimelineProfileValues>) => {
      if (formikProps.dirty) {
        openConfirmModal({
          title: <Title order={3}>{t('profile.unsavedChangesTitle')}</Title>,
          centered: true,
          children: <Text size="sm">{t('profile.unsavedChangesText')}</Text>,
          labels: {
            confirm: t('labels.close'),
            cancel: t('labels.cancel'),
          },
          onConfirm: () => {
            formikProps.resetForm();
            onClose();
          },
        });
      } else {
        onClose();
      }
    },
    [onClose, t],
  );

  const editorTheme = useMemo(
    () =>
      EditorView.theme({
        '&': {
          fontFamily: theme.fontFamily,
          backgroundColor: isDark ? theme.colors.dark[7] : theme.white,
        },
        '.cm-content': {
          fontFamily: theme.fontFamily,
          padding: '10px 12px',
          color: isDark ? theme.colors.dark[0] : theme.black,
        },
        '.cm-line': {
          padding: '0',
          lineHeight: '1.55',
        },
        '&.cm-editor.cm-focused': {
          outline: 'none',
        },
        '.cm-cursor': {
          borderLeftColor: theme.colors[theme.primaryColor][isDark ? 5 : 4],
        },
        '.cm-selectionBackground': {
          backgroundColor: `${rgba(theme.colors[theme.primaryColor][isDark ? 5 : 4], 0.2)} !important`,
        },
        '&.cm-focused .cm-selectionBackground': {
          backgroundColor: `${rgba(theme.colors[theme.primaryColor][isDark ? 5 : 4], 0.3)} !important`,
        },
        '&.cm-editor': {
          border: 'none',
        },
      }),
    [theme, isDark],
  );

  return (
    <Modal
      opened={opened}
      onClose={() => void 0}
      title={
        <Text size="lg" fw="bolder">
          {t('profile.editTimelineEntry')}
        </Text>
      }
      size="xl"
      withCloseButton={false}
      fullScreen={isSmallScreen}
      styles={{
        body: {
          maxHeight: isSmallScreen
            ? 'calc(100vh - 140px)'
            : 'calc(90vh - 5rem)',
          overflowY: 'auto',
          paddingBottom: isSmallScreen ? '80px' : undefined,
        },
        content: {
          maxHeight: isSmallScreen ? '100vh' : '90vh',
          display: 'flex',
          flexDirection: 'column',
        },
        header: {
          padding: isSmallScreen ? '1rem 1rem 0.5rem' : '1.5rem 2rem 0.5rem',
          position: 'sticky',
          top: 0,
          backgroundColor: isDark ? theme.colors.dark[7] : theme.white,
          zIndex: 10,
          borderBottom: `1px solid ${isDark ? theme.colors.dark[4] : theme.colors.gray[3]}`,
        },
        inner: {
          padding: 0,
        },
      }}
    >
      <Box p={isSmallScreen ? '1rem' : '2rem'} style={{ flex: 1 }}>
        <Formik<TimelineProfileValues>
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={TimelineSchema}
        >
          {(formikProps) => (
            <Form
              style={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Stack style={{ flex: 1 }}>
                <Field
                  as={TextInput}
                  label={
                    <FormLabel
                      label={t('timeline.title')}
                      tooltip={t('timeline.titleTooltip')}
                    />
                  }
                  placeholder={t('timeline.titlePlaceholder')}
                  name="title"
                  error={formikProps.touched.title && formikProps.errors.title}
                />
                <Field
                  as={TextInput}
                  label={
                    <FormLabel
                      label={t('timeline.company')}
                      tooltip={t('timeline.companyTooltip')}
                    />
                  }
                  placeholder={t('timeline.companyPlaceholder')}
                  name="company"
                  error={
                    formikProps.touched.company && formikProps.errors.company
                  }
                  icon={<IconBuildingSkyscraper />}
                />
                <Flex gap="1rem">
                  <Field
                    as={TextInput}
                    label={
                      <FormLabel
                        label={t('timeline.startDate')}
                        tooltip={t('timeline.startDateTooltip')}
                      />
                    }
                    placeholder={t('timeline.startDatePlaceholder')}
                    name="from"
                    error={formikProps.touched.from && formikProps.errors.from}
                    icon={<IconCalendar />}
                  />
                  <Field
                    as={TextInput}
                    label={
                      <FormLabel
                        label={t('timeline.endDate')}
                        tooltip={t('timeline.endDateTooltip')}
                      />
                    }
                    placeholder={t('timeline.endDatePlaceholder')}
                    name="to"
                    error={formikProps.touched.to && formikProps.errors.to}
                    icon={<IconCalendar />}
                  />
                </Flex>
                <Stack gap="0.5rem">
                  <Group justify="space-between" align="center" mb="0.25rem">
                    <FormLabel
                      label={t('timeline.description')}
                      tooltip={t('timeline.descriptionTooltip')}
                    />
                    <Group gap="md" align="center">
                      <Button
                        variant="gradient"
                        radius="xl"
                        onClick={triggerOptimization}
                        loading={generateSuggestionMutation.isPending}
                        disabled={generateSuggestionMutation.isPending}
                        leftSection={
                          <IconSparkles
                            size="1.125rem"
                            className={classes.sparkleIcon}
                          />
                        }
                        className={classes.button}
                      >
                        {generateSuggestionMutation.isPending
                          ? t('timeline.generating')
                          : t('timeline.optimize')}
                      </Button>
                    </Group>
                  </Group>
                  <Paper withBorder>
                    <Field name="description">
                      {({
                        field,
                        form,
                      }: {
                        field: { name: string; value: string };
                        form: FormikProps<TimelineProfileValues>;
                      }) => (
                        <CodeMirror
                          ref={editorRef}
                          value={field.value}
                          height="18.75rem"
                          theme={isDark ? 'dark' : 'light'}
                          style={{
                            fontSize: theme.fontSizes.sm,
                          }}
                          onChange={(value: string) => {
                            void form.setFieldValue('description', value);
                          }}
                          basicSetup={{
                            highlightSpecialChars: true,
                            history: true,
                            drawSelection: true,
                            syntaxHighlighting: true,
                            defaultKeymap: true,
                            autocompletion: true,
                            lineNumbers: false,
                            foldGutter: false,
                          }}
                          extensions={[
                            EditorView.lineWrapping,
                            markdown(),
                            editorTheme,
                          ]}
                        />
                      )}
                    </Field>
                  </Paper>
                </Stack>
              </Stack>
              <Box
                style={{
                  position: isSmallScreen ? 'fixed' : 'static',
                  bottom: 0,
                  left: 0,
                  right: 0,
                  padding: isSmallScreen ? '1rem' : 0,
                  backgroundColor: isDark ? theme.colors.dark[7] : theme.white,
                  borderTop: isSmallScreen
                    ? `1px solid ${isDark ? theme.colors.dark[4] : theme.colors.gray[3]}`
                    : 'none',
                  zIndex: 10,
                }}
              >
                <Group justify="space-between" mt={isSmallScreen ? 0 : 'xl'}>
                  {section?.id && (
                    <Button
                      leftSection={<IconTrash size="0.9rem" />}
                      variant="light"
                      size={isSmallScreen ? 'sm' : 'xs'}
                      onClick={handleDelete}
                      disabled={formikProps.isSubmitting}
                      color="red.5"
                      c="red.6"
                    >
                      {t('labels.delete')}
                    </Button>
                  )}
                  <Group justify="flex-end" gap="sm">
                    <Button
                      variant="light"
                      size="sm"
                      onClick={() => {
                        handleClose(formikProps);
                      }}
                      disabled={formikProps.isSubmitting}
                    >
                      {t('labels.close')}
                    </Button>
                    <Button
                      type="submit"
                      size="sm"
                      variant="filled"
                      leftSection={<IconCheck />}
                      loading={formikProps.isSubmitting}
                      disabled={
                        !formikProps.isValid ||
                        formikProps.isSubmitting ||
                        !formikProps.dirty
                      }
                    >
                      {formikProps.isSubmitting
                        ? t('labels.saving')
                        : t('labels.save')}
                    </Button>
                  </Group>
                </Group>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
};
