import {
  AppShell,
  NavLink,
  Text,
  ThemeIcon,
  MantineTheme,
} from '@mantine/core';
import {
  IconFile,
  IconFileTypePdf,
  IconInfoCircle,
  IconMail,
  IconMessageChatbot,
  IconPencil,
  IconBriefcase,
} from '@tabler/icons-react';
import { TFunction } from 'i18next';
import React, { useState, useMemo } from 'react';
import { motion } from 'framer-motion';
import { AppRouteURL } from '../../AppRouteURL';
import { IResume } from '../../models/Resume';
import { MenuNavLink, MenuNavLinkProps } from './MenuNavLink';
import { navGroupAnimation } from './NavAnimations';
import { useLocation } from 'react-router-dom';
import { isRouteInSection } from './NavUtils';

interface ApplicationMenuProps {
  setOpen: MenuNavLinkProps['setOpen'];
  resume: IResume;
  t: TFunction;
}

export const ApplicationMenu: React.FC<ApplicationMenuProps> = ({
  setOpen,
  resume,
  t,
}) => {
  const location = useLocation();

  // Simply check if the current path starts with the resume view path for this resume
  const applicationPrefix = `${AppRouteURL.resumes.view}/${resume.id}`;

  // Determine if any route in this application is active
  const isApplicationActive = useMemo(
    () => isRouteInSection(location.pathname, [applicationPrefix]),
    [location.pathname, applicationPrefix],
  );

  // User-controlled state override
  const [applicationControlledState, setApplicationControlledState] = useState<
    boolean | null
  >(null);

  // Calculate the actual open state - use controlled state if it's not null, otherwise use active state
  const applicationOpened =
    applicationControlledState !== null
      ? applicationControlledState
      : isApplicationActive;

  return (
    <AppShell.Section>
      <NavLink
        href={`#application-${resume.id}`}
        label={
          <Text fw={500} fz="sm" truncate="end">
            {resume.title}
          </Text>
        }
        leftSection={
          <ThemeIcon
            variant="light"
            size="lg"
            style={{
              background:
                'linear-gradient(135deg, var(--mantine-color-blue-6), var(--mantine-color-violet-6))',
              border: 'none',
              boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05)',
            }}
          >
            <IconBriefcase style={{ color: 'white', stroke: '1.5' }} />
          </ThemeIcon>
        }
        opened={applicationOpened}
        onChange={setApplicationControlledState}
        defaultOpened={isApplicationActive}
        tabIndex={0}
        styles={(theme: MantineTheme) => ({
          root: {
            borderRadius: theme.radius.md,
            padding: '12px',
            transition: 'all 0.2s ease',
            '&[data-expanded]': {
              backgroundColor: `var(--mantine-color-${theme.primaryColor}-0)`,
            },
          },
          label: {
            fontWeight: 500,
          },
        })}
      >
        <motion.div
          variants={navGroupAnimation}
          initial="initial"
          animate="animate"
        >
          <MenuNavLink
            leftSection={
              <ThemeIcon
                variant="light"
                size="md"
                style={{
                  background: 'var(--mantine-color-blue-0)',
                  border: 'none',
                  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.02)',
                }}
              >
                <IconFile
                  style={{
                    color: 'var(--mantine-color-blue-6)',
                    stroke: '1.5',
                  }}
                />
              </ThemeIcon>
            }
            to={`${AppRouteURL.resumes.view}/${resume.id}`}
            label={<Text fz="sm">{t('view.resumeTab')}</Text>}
            setOpen={setOpen}
          />
          <MenuNavLink
            leftSection={
              <ThemeIcon
                variant="light"
                size="md"
                style={{
                  background: 'var(--mantine-color-blue-0)',
                  border: 'none',
                  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.02)',
                }}
              >
                <IconMail
                  style={{
                    color: 'var(--mantine-color-blue-6)',
                    stroke: '1.5',
                  }}
                />
              </ThemeIcon>
            }
            to={`${AppRouteURL.resumes.view}/${resume.id}/letter`}
            label={<Text fz="sm">{t('view.coverLetterTab')}</Text>}
            setOpen={setOpen}
          />
          <MenuNavLink
            leftSection={
              <ThemeIcon
                variant="light"
                size="md"
                style={{
                  background: 'var(--mantine-color-blue-0)',
                  border: 'none',
                  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.02)',
                }}
              >
                <IconInfoCircle
                  style={{
                    color: 'var(--mantine-color-blue-6)',
                    stroke: '1.5',
                  }}
                />
              </ThemeIcon>
            }
            to={`${AppRouteURL.resumes.view}/${resume.id}/info`}
            label={<Text fz="sm">{t('view.infoTab')}</Text>}
            setOpen={setOpen}
          />
          <MenuNavLink
            leftSection={
              <ThemeIcon
                variant="light"
                size="md"
                style={{
                  background: 'var(--mantine-color-blue-0)',
                  border: 'none',
                  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.02)',
                }}
              >
                <IconPencil
                  style={{
                    color: 'var(--mantine-color-blue-6)',
                    stroke: '1.5',
                  }}
                />
              </ThemeIcon>
            }
            to={`${AppRouteURL.resumes.view}/${resume.id}/history`}
            label={<Text fz="sm">{t('view.historyTab')}</Text>}
            setOpen={setOpen}
          />
          <MenuNavLink
            leftSection={
              <ThemeIcon
                variant="light"
                size="md"
                style={{
                  background: 'var(--mantine-color-blue-0)',
                  border: 'none',
                  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.02)',
                }}
              >
                <IconFileTypePdf
                  style={{
                    color: 'var(--mantine-color-blue-6)',
                    stroke: '1.5',
                  }}
                />
              </ThemeIcon>
            }
            to={`${AppRouteURL.resumes.view}/${resume.id}/pdf`}
            label={<Text fz="sm">{t('view.pdfTab')}</Text>}
            setOpen={setOpen}
          />
          <MenuNavLink
            leftSection={
              <ThemeIcon
                variant="light"
                size="md"
                style={{
                  background: 'var(--mantine-color-blue-0)',
                  border: 'none',
                  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.02)',
                }}
              >
                <IconMessageChatbot
                  style={{
                    color: 'var(--mantine-color-blue-6)',
                    stroke: '1.5',
                  }}
                />
              </ThemeIcon>
            }
            to={`${AppRouteURL.resumes.view}/${resume.id}/interview`}
            label={<Text fz="sm">{t('view.interviewTab')}</Text>}
            setOpen={setOpen}
          />
        </motion.div>
      </NavLink>
    </AppShell.Section>
  );
};
