import {
  Center,
  Container,
  Grid,
  Group,
  Stack,
  Text,
  Title,
  useMantineTheme,
} from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { motion, Variants, useAnimation } from 'framer-motion';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Bowl } from '../../components/Bowl/Bowl';
import { CreateResumeButton } from '../../components/CreateResumeButton/CreateResumeButton';
import { useResumesQuery } from '../../queries/ResumeQueries';
import { useProfileQuery } from '../../models/ProfileQueries';
import AnalyzerCard from './AnalyzerCard';
import ApplicationActivityCard from './ApplicationActivityCard';
import ApplicationStatusCard from './ApplicationStatusCard';
import OnboardingModal from './OnboardingModal';
import ProfileStatusCard from './ProfileStatusCard';
import RecommendationCard from './RecommendationCard';
import SubscribeCard from './SubscribeCard';
import classes from './Home.module.css';

const easing = [0.6, -0.05, 0.01, 0.99];

const fadeInUp: Variants = {
  initial: {
    y: 100,
    opacity: 0,
    transition: { duration: 1.0, ease: easing },
  },
  animate: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 1.0,
      ease: easing,
    },
  },
};

const fadeInDown: Variants = {
  initial: {
    y: -100,
    opacity: 0,
    transition: { duration: 1.0, ease: easing },
  },
  animate: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 1.0,
      ease: easing,
    },
  },
};

const fadeInLeft: Variants = {
  initial: {
    x: -200,
    opacity: 0,
    transition: { duration: 1.0, ease: easing },
  },
  animate: {
    x: 0,
    opacity: 1,
    transition: {
      duration: 1.0,
      ease: easing,
    },
  },
};

const fadeInRight: Variants = {
  initial: {
    x: 200,
    opacity: 0,
    transition: { duration: 1.0, ease: easing },
  },
  animate: {
    x: 0,
    opacity: 1,
    transition: {
      duration: 1.0,
      ease: easing,
    },
  },
  exit: {
    x: 0,
    opacity: 1,
  },
};

const titleAnimation: Variants = {
  initial: {
    opacity: 0,
    scale: 0.9,
    y: 20,
  },
  animate: {
    opacity: 1,
    scale: 1,
    y: 0,
    transition: {
      duration: 0.8,
      ease: [0.4, 0, 0.2, 1],
    },
  },
};

const Home: React.FC = () => {
  const { t } = useTranslation();
  const { data: resumes } = useResumesQuery();
  const { data: profile } = useProfileQuery();
  const theme = useMantineTheme();
  const smallScreen = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`);
  const controls = useAnimation();

  useEffect(() => {
    void controls.start('animate');
  }, [controls]);

  const getTimeBasedGreeting = () => {
    const hour = new Date().getHours();
    if (hour < 12) return t('greetings.morning');
    if (hour < 18) return t('greetings.afternoon');
    return t('greetings.evening');
  };

  return (
    <Container size="xl">
      <Bowl title={t('home.pageTitle')} />
      <Stack gap="xl">
        <motion.div
          initial="initial"
          animate={controls}
          variants={fadeInDown}
          className={classes.header}
          style={{ padding: theme.spacing.xl, marginBottom: theme.spacing.xl }}
        >
          <motion.div
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{
              duration: 0.6,
              delay: 0.2,
              ease: [0.4, 0, 0.2, 1],
            }}
          >
            <Text className={classes.greeting} size="xl" fw={500} mb="xs">
              {getTimeBasedGreeting()}
            </Text>
          </motion.div>

          <motion.div
            variants={titleAnimation}
            initial="initial"
            animate="animate"
          >
            <Title
              className={classes.title}
              size={smallScreen ? 42 : 52}
              my="md"
            >
              {profile?.firstName
                ? t('welcomeNamed', { name: profile.firstName })
                : t('welcomeHuman')}
            </Title>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{
              duration: 0.6,
              delay: 0.6,
              ease: [0.4, 0, 0.2, 1],
            }}
          >
            <Text
              className={classes.subtitle}
              size={smallScreen ? 'md' : 'lg'}
              c="dimmed"
              maw={600}
              mx="auto"
              mt="md"
              lh={1.6}
            >
              {t('home.subtitle')}
            </Text>
          </motion.div>
        </motion.div>

        {smallScreen && (
          <Center my="lg">
            <CreateResumeButton />
          </Center>
        )}

        <Grid gutter={{ base: 'md', sm: 'lg' }} align="stretch">
          {Array.isArray(resumes) && resumes.length === 0 && (
            <Grid.Col span={12}>
              <motion.div
                initial="initial"
                animate="animate"
                variants={fadeInDown}
                style={{ height: '100%' }}
              >
                <RecommendationCard />
              </motion.div>
            </Grid.Col>
          )}
          <Grid.Col span={{ base: 12, md: 6 }}>
            <motion.div
              initial="initial"
              animate="animate"
              variants={fadeInLeft}
              style={{ height: '100%' }}
            >
              <ProfileStatusCard />
            </motion.div>
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6 }}>
            <motion.div
              initial="initial"
              animate="animate"
              exit="exit"
              variants={fadeInRight}
              style={{ height: '100%' }}
            >
              <ApplicationStatusCard />
            </motion.div>
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6 }}>
            <motion.div
              initial="initial"
              animate="animate"
              variants={fadeInUp}
              style={{ height: '100%' }}
            >
              <SubscribeCard />
            </motion.div>
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6 }}>
            <motion.div
              initial="initial"
              animate="animate"
              variants={fadeInUp}
              style={{ height: '100%' }}
            >
              <AnalyzerCard />
            </motion.div>
          </Grid.Col>
          <Grid.Col span={12}>
            <motion.div
              initial="initial"
              animate="animate"
              variants={fadeInUp}
              style={{ height: '100%' }}
            >
              <ApplicationActivityCard />
            </motion.div>
          </Grid.Col>
        </Grid>

        <motion.div initial="initial" animate="animate" variants={fadeInUp}>
          <Group justify="center" mt="xl">
            <CreateResumeButton />
          </Group>
        </motion.div>
      </Stack>
      <OnboardingModal />
    </Container>
  );
};

export default Home;
